<template>
  <div>
    <div class="row">
      <div class="col-lg-8">
        <div class="row col-12">
          <v-select
            v-if="!es_proveedor"
            class="m-2"
            style="width: 195px"
            placeholder="Filtrar Empresa"
            label="Alias"
            :options="empresas"
            :reduce="empresa => empresa.EmpresaId"
            :searchable="false"
            :clearable="false"
            @input="cambiarEmpresa"
            v-model="idEmpresa">
            <template #selected-option="{ Alias }">
              <span>Empresa: {{ Alias }}</span>
            </template>
          </v-select>
        <b-button class="btn-detail info m-2 "
          v-if="$validar(['Facturas']) && !es_proveedor"
          @click="selectedRows.length >= 1 && !loading ? validarCfdis() : undefined"
          :disabled="selectedRows.length < 1 || loading">
          <font-awesome-icon v-if="loadingVal" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin />
          <font-awesome-icon v-else :icon="{ prefix: 'fa', iconName: 'globe', }" class="icon btn-icon"/> Validar en SAT
        </b-button>
        <b-button class="btn-detail m-2"
          v-if="$descargar(['Facturas']) && !es_proveedor"
          @click="selectedRows.length >= 1 && !loading ? descargarSeleccionados() : undefined"
          :disabled="selectedRows.length < 1 || loading"
          v-b-popover.hover="'Descargar archivos seleccionados'">
          <font-awesome-icon v-if="loadingDown" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin />
          <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'download', }" class="icon btn-icon"/> Descargar seleccionados
        </b-button>
        <b-button class="btn-detail m-2"
          v-if="!todas"
          @click="selectedRows.length >= 1 && !(selectedRows.length === 1 && selectedRows[0].data.Tipo === 'P') && !loading ? generarContrarecibo() : undefined"
          :disabled="selectedRows.length < 1 || (selectedRows.length === 1 && selectedRows[0].data.Tipo === 'P') || loading"
          v-b-popover.hover="'Generar contra recibo de los archivos seleccionados'">
          <font-awesome-icon v-if="loadingRecibo" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin />
          <font-awesome-icon v-else :icon="{ prefix: 'fa', iconName: 'file-pdf', }" class="icon btn-icon"/> Generar contra recibos
        </b-button>
        </div>
      </div>
      <div class="col-lg-4 text-right">
        <b-button class="btn-detail m-2"
          v-if="$ver(['Facturas'])"
          @click="!loading ? getData() : undefined"
          v-shortkey.once="['f5']"
          @shortkey="getData()"
          :disabled="loading"
          style="display: none;"
          v-b-popover.hover="'Actualizar listado'">
          Actualizar
        </b-button>
        <b-button class="btn-detail m-2"
          v-if="$eliminar(['Facturas']) && !es_proveedor && !todas"
          @click="!loading ? showDeleteAllFiles() : undefined"
          :disabled="loading"
          v-b-popover.hover="'Eliminar todos los archivos'">
          <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'trash-alt', }" class="icon btn-icon"/> Eliminar todo
        </b-button>
        <b-button class="btn-detail m-2"
          @click="!loading ? updateTable() : undefined"
          :disabled="loading"
          v-b-popover.hover="'Actualizar listado'">
          <font-awesome-icon v-if="loading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin />
          <font-awesome-icon v-else :icon="{ prefix: 'fa', iconName: 'sync-alt', }" class="icon btn-icon"/>
        </b-button>
      </div>
    </div>
    <!-- Tabla de datos -->
    <transition name="slide-fade" appear>
      <ag-grid-vue
          class="ag-grid-table ag-theme-balham"
          style='height: 60vh'
          :localeText="locale"
          :animateRows="true"
          rowSelection="multiple"
          :loadingCellRenderer="'loadingCellRenderer'"
          :getRowId="getRowId"

          :gridOptions="gridOptions"
          :context="context"
          :suppressDragLeaveHidesColumns="true"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :statusBar="statusBar"
          :overlayNoRowsTemplate="$overlayNoRowsTemplate()"
          :frameworkComponents="frameworkComponents"

          :rowModelType="rowModelType"
          :cacheBlockSize="paginationPageSize"

          @grid-ready="onGridReady"
          @gridSizeChanged="gridSizeChanged"
          @row-selected="rowSelected"
          @rowDoubleClicked="rowDblClick"
          @column-resized="saveColumnState"
          @column-moved="saveColumnState"
          @sort-changed="saveColumnState"
          @first-data-rendered="gridFirstDataRendered">
      </ag-grid-vue>
    </transition>

    <b-modal
        id="bv-modal-viewfile" size="xl" centered hide-footer content-class="site-modal modal-viewer"
        :header-text-variant="'light'">
        <template #modal-title>Visualizar</template>
        <b-card class="min-padd" no-body>
          <b-tabs class="tabs-upload" small card>

            <b-tab title="Archivo">
              <div v-if="srcFile && !(loadingViewId > 0)">
                <prism class="line-numbers"  v-if="xmlType" language="xml">{{ $formatXml(srcFile) }}</prism>
                <iframe v-else
                  :src="srcFile"
                  @load="iFrameLoaded"
                  frameBorder="0"
                  scrolling="auto"
                  height="100%"
                  width="100%"
                ></iframe>
              </div>
              <div v-else class="empty-image center-content">
                <span v-if="!(loadingViewId > 0)">
                  <img src="@/assets/empty.svg" alt="Empty">
                  <p class="empty-description">Sin archivo cargado</p>
                </span>
                <span v-else><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/></span>
              </div>
            </b-tab>

            <b-tab title="Metadata">
              <!-- <json-viewer :value="JSON.parse(this.metadata)" expanded copyable></json-viewer> -->
              <prism class="line-numbers" language="json">{{ JSON.parse(this.metadata) }}</prism>
            </b-tab>

          </b-tabs>
        </b-card>

      </b-modal>

      <b-modal
        id="bv-modal-viewEstatus" size="xl" centered hide-footer content-class="site-modal"
        :header-text-variant="'light'">
        <template #modal-title>VALIDACIONES {{ nomArchivoSelected }}</template>
        <b-card class="min-padd" no-body>
          <validaciones-box :validaciones="validaciones" />
        </b-card>

      </b-modal>

      <receipt class="hidden" ref="recibo" :fecha-carga="fechaCarga" :proveedor="nomProveedor" :partidas-empresas="partidasRecibo"/>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import apiClient from '@/services/axios';
import ValidacionesBox from '@/components/ValidacionesBox.vue';
import Prism from 'vue-prism-component';
import 'prismjs';
import 'prismjs/themes/prism.css';
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import 'prismjs/components/prism-json'; // need this
import AgCellFirst from '@/components/ArchivosTable/AgCellFirst.vue';
import AgCellActions from '@/components/ArchivosTable/AgCellActions.vue';
import AgCellPopoverVue from '@/components/AgCellPopover.vue';
import AgCellEstatusBadge from '@/components/ArchivosTable/AgCellEstatusBadge.vue';
import AgCellTotalReps from '@/components/ArchivosTable/AgCellTotalReps.vue';
import AgStatusBar from '@/components/AgStatusBar.vue';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import { mapState } from 'vuex';

import Receipt from '@/components/Recibo.vue';
import html2pdf from 'html2pdf.js';

export default {
  name: 'ArchivosTable',
  components: {
    ValidacionesBox,
    Prism,
    AgGridVue,
    Receipt,
  },
  props: [
    'provId',
    'es_proveedor',
    'nom_prov',
    'todas',
    'refresh',
    'archivos',
  ],
  data() {
    return {
      loadingViewId: 0,
      loadingDownId: 0,
      loadingDelId: 0,
      loading: false,
      loadingDown: false,
      loadingVal: false,
      /** Datos para generar recibo */
      loadingRecibo: false,
      fechaCarga: '',
      nomProveedor: '',
      partidasRecibo: [],
      /** */
      validaciones: [],
      nomArchivoSelected: '',
      srcFile: '',
      xmlType: false,
      metadata: '{}',
      idEmpresa: 0,
      empresas: [],
      /** Configuracion de tabla ag-grid */
      // eslint-disable-next-line no-undef
      locale: AG_GRID_LOCALE_ES,
      context: null,
      gridOptions: {
        nameTable: 'agCfdis',
        suppressMenuHide: true,
      },
      defaultColDef: {
        floatingFilter: true,
        resizable: true,
        sortable: true,
        filter: true,
        unSortIcon: true,
      },
      statusBar: {
        statusPanels: [
          { statusPanel: 'statusBarComponent', align: 'left' },
          { statusPanel: 'agSelectedRowCountComponent' },
        ],
      },
      frameworkComponents: {
        firstColTemplate: AgCellFirst,
        actionsTemplate: AgCellActions,
        popoverTemplate: AgCellPopoverVue,
        estatusTemplate: AgCellEstatusBadge,
        totalRepsTemplate: AgCellTotalReps,
        statusBarComponent: AgStatusBar,
      },
      selectedRows: [],
      selectedRowKeys: [],
      getRowId: (params) => params.data.FacturaId,
      columnDefs: [
        {
          headerName: '#',
          field: 'FacturaId',
          filter: false,
          checkboxSelection: true,
          width: '80px',
          cellClass: 'text-left line-numbers',
          cellRendererSelector: () => ({
            component: 'firstColTemplate',
          }),
        },
        {
          headerName: 'Fecha Carga',
          field: 'Fecha',
          width: '150px',
          cellClass: 'text-center',
          cellRendererSelector: (params) => ({
            component: 'popoverTemplate',
            params: {
              popoverText: `<strong>Fecha:</strong> ${params.data.Fecha}<br><strong>Autor:</strong> ${params.data.Autor}`,
            },
          }),
          filter: 'agDateColumnFilter',
        },
        {
          headerName: 'Emisión',
          field: 'FechaDoc',
          width: '150px',
          cellClass: 'text-center',
          filter: 'agDateColumnFilter',
        },
        {
          headerName: 'Archivo',
          field: 'NomArchivo',
          cellRendererSelector: (params) => ({
            component: 'popoverTemplate',
            params: {
              popoverText: `<strong>Archivo:</strong> ${params.data.NomArchivo}`,
            },
          }),
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Folio',
          field: 'Folio',
          width: '90px',
          cellClass: 'text-center',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'UUID',
          field: 'UUID',
          width: '150px',
          cellClass: 'text-center',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Emisor',
          field: 'RfcEmisor',
          width: '130px',
          cellClass: 'text-center',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Receptor',
          field: 'RfcReceptor',
          width: '130px',
          cellClass: 'text-center',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Uso',
          field: 'Uso',
          width: '90px',
          cellClass: 'text-center',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'M.P.',
          field: 'MetodoPago',
          width: '90px',
          cellClass: 'text-center',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'F.P.',
          field: 'FormaPago',
          width: '90px',
          cellClass: 'text-center',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Total',
          field: 'Total',
          width: '100px',
          valueFormatter: (params) => this.$currencyFormat(params?.value),
          cellClass: 'text-center',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Moneda',
          field: 'Moneda',
          width: '90px',
          cellClass: 'text-center',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Tipo',
          field: 'Tipo',
          // eslint-disable-next-line no-nested-ternary
          cellRenderer: (params) => (params.value === 'E' ? 'N.C.' : (params.value === 'P' ? 'REP' : 'FACTURA')),
          width: '90px',
          cellClass: 'text-center',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Total REPS',
          field: 'TotalReps',
          width: '120px',
          cellRendererSelector: () => ({
            component: 'totalRepsTemplate',
          }),
          // valueFormatter: (params) => (params?.data?.Tipo !== 'I' ? 'N/A' : this.$currencyFormat(params?.value)),
          cellClass: 'text-center',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'SAT',
          field: 'EstatusSAT',
          cellRendererSelector: () => ({
            component: 'estatusTemplate',
          }),
          pinned: 'right',
          width: '100px',
          cellClass: 'text-center',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Acciones',
          filter: false,
          floatingFilter: false,
          suppressMenu: true,
          sortable: false,
          lockPosition: 'right',
          suppressMovable: true,
          pinned: 'right',
          width: '215px',
          cellClass: 'text-center',
          cellRendererSelector: () => ({
            component: 'actionsTemplate',
            params: {
              loading: this.loading,
              loadingViewId: this.loadingViewId,
              loadingDownId: this.loadingDownId,
              loadingDelId: this.loadingDelId,
              es_proveedor: this.es_proveedor,
              todas: this.todas,
            },
          }),
        },
      ],
      rowModelType: 'serverSide',
      paginationPageSize: 30,
      firstRender: true,
      filters: '',
    };
  },
  computed: {
    ...mapState(['aggrid']),
  },
  mounted() {
    this.context = { componentParent: this };
    this.getEmpresas();
  },
  methods: {
    // Evento cuando la tabla se inicializa
    onGridReady(params) {
      // Creamos variable global de params (propiedates y funciones de agGrid)
      this.agGrid = params;
      // Llamada a la funcion que actualiza la tabla
      this.getData();
      this.gridSizeChanged(this.agGrid);
      // this.idEmpresa = { EmpresaId: 0, Nombre: '(TODAS)', Alias: '(TODAS)' };
    },
    // Metodo para ajustar tamaño de tabla cuando las columnas no cubren por completo el grid
    gridSizeChanged(params) {
      const allColumnIds = [];
      params.columnApi.getAllColumns().forEach((column) => {
        if (!column.colDef.autoSize) { allColumnIds.push(column.colId); }
      });

      // params.columnApi.autoSizeColumns(allColumnIds);
    },
    saveColumnState() {
      const columnState = this.agGrid.columnApi?.getColumnState();
      this.$store.commit('SAVE_AGGRIDSTATE', { tblname: `${this.gridOptions?.nameTable}State`, columnstate: columnState });
    },
    gridFirstDataRendered() {
      const columnState = this.aggrid[`${this.gridOptions?.nameTable}State`];
      if (columnState) {
        this.agGrid.columnApi.applyColumnState({
          state: columnState,
          applyOrder: true,
        });
      }
    },
    // Metodo para actualizar filas seleccionadas
    rowSelected(params) {
      this.selectedRows = params.api.getSelectedNodes();
      this.selectedRowKeys = this.selectedRows.map((node) => node.data.FacturaId);
    },
    // Metodo para abrir modal al dar doble click en una fila
    rowDblClick(/* params */) {
      if (this.$permisos_or([['Facturas', 'Ver'], ['Facturas', 'Editar']])) {
        // this.showModalEdit(params.data);
      }
    },
    // Metodo para limpiar todos los filtros
    clearFilters() {
      if (Object.entries(this.filters.filterModel).length) this.loading = true;
      this.agGrid.api.setFilterModel(null);
    },
    // Metodo para actualiza datos de la tabla incluyendo filtros
    updateTable() {
      this.getData();
      // this.loading = true
      // this.agGrid.api.refreshInfiniteCache()
    },
    // Metodo para actualizar datos de la tabla (server - side)
    getData() {
      const endpoint = this.todas ? 'facturas/all' : 'facturas';
      this.agGrid.api.setServerSideDatasource(
        this.$createDataSource(
          this,
          apiClient,
          endpoint, // ruta
          {
            ProvId: this.provId,
            empresaId: this.idEmpresa,
          },
        ),
      );
      // console.log(this.agGrid.api.totalRows);
    },
    getFiles() {
      this.loading = true;
      const endpoint = this.todas ? '/facturas/all' : '/facturas';
      apiClient.get(endpoint, {
        params: {
          ProvId: this.provId,
        },
      })
        .then((res) => {
          this.archivos = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    downloadFiles(facturaId, nomArchivo) {
      this.loadingDownId = facturaId;
      const endpoint = this.todas ? '/facturas/all/archivo' : '/facturas/archivo';
      // DEscarga de XML
      apiClient.get(endpoint, {
        responseType: 'blob',
        params: {
          ProvId: this.provId,
          facturaId,
          esXml: true,
        },
      })
        .then((resXml) => {
          const linkXml = document.createElement('a');
          linkXml.href = URL.createObjectURL(resXml.data);
          linkXml.download = `${nomArchivo}.xml`;
          linkXml.click();
          URL.revokeObjectURL(resXml.data);
          URL.revokeObjectURL(linkXml.href);
          // Descarga de PDF
          apiClient.get(endpoint, {
            responseType: 'blob',
            params: {
              ProvId: this.provId,
              facturaId,
              esXml: false,
            },
          })
            .then((resPdf) => {
              const linkPdf = document.createElement('a');
              linkPdf.href = URL.createObjectURL(resPdf.data);
              linkPdf.download = `${nomArchivo}.pdf`;
              linkPdf.click();
              URL.revokeObjectURL(resPdf.data);
              URL.revokeObjectURL(linkPdf.href);
              this.loadingDownId = 0;
            })
            .catch(() => {
              this.loadingDownId = 0;
            });
        })
        .catch(() => {
          this.loadingDownId = 0;
        });
    },
    viewFile(facturaId, metadata, formato) {
      this.loadingViewId = facturaId;
      this.metadata = metadata;
      this.srcFile = '';
      this.xmlType = formato === 'X';
      const endpoint = this.todas ? '/facturas/all/archivo' : '/facturas/archivo';
      apiClient.get(endpoint, {
        responseType: this.xmlType ? 'text/xml' : 'blob', // Si es XML es X si es PDF es P
        params: {
          ProvId: this.provId,
          facturaId,
          esXml: this.xmlType,
        },
      })
        .then((res) => {
          if (this.xmlType) {
            this.srcFile = res.data;
            this.srcFile = this.srcFile.replaceAll('>', '>\n');
          } else {
            this.srcFile = URL.createObjectURL(res.data);
            URL.revokeObjectURL(res.data);
          }
          this.loadingViewId = 0;
        })
        .catch(() => {
          this.loadingViewId = 0;
        });

      this.$bvModal.show('bv-modal-viewfile');
    },
    showDeleteFile(id) {
      this.$bvModal.msgBoxConfirm('Si continua, se eliminará el archivo seleccionado.', {
        title: 'Eliminar archivo',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Si, Eliminar',
        cancelTitle: 'Cancelar',
        headerClass: 'text-light',
        bodyClass: 'max-height-70',
        footerClass: 'bg-light p-2',
        hideHeaderClose: true,
        centered: true,
      })
        .then((confirm) => {
          if (confirm) this.deleteFile(id);
        })
        .catch(() => {});
    },
    deleteFile(facturaId) {
      this.loadingDelId = facturaId;
      apiClient.delete('/facturas', {
        data: {
          ProvId: this.provId,
          FacturaId: facturaId,
        },
      })
        .then(() => {
          this.loadingDelId = 0;
          this.destroyFile();
        })
        .catch(() => {
          this.loadingDelId = 0;
        });
    },
    destroyFile() {
      this.getData();
      // this.$emit('destroyed');
    },
    getValidaciones(facturaId, nomArchivo) {
      this.nomArchivoSelected = nomArchivo;
      this.validaciones = [];
      apiClient.get(`/orden/${this.ordenId}/validaciones`, {
        params: {
          ProvId: this.provId,
          facturaId: [facturaId],
        },
      })
        .then((res) => {
          this.validaciones = res.data;
        })
        .catch(() => {});

      this.$bvModal.show('bv-modal-viewEstatus');
    },
    showDeleteAllFiles() {
      this.$bvModal.msgBoxConfirm('Si continua, se eliminarán todos los Cfdis de este proveedor.', {
        title: 'Eliminar archivos',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Si, Eliminar',
        cancelTitle: 'Cancelar',
        headerClass: 'text-light',
        bodyClass: 'max-height-70',
        footerClass: 'bg-light p-2',
        hideHeaderClose: true,
        centered: true,
      })
        .then((confirm) => {
          if (confirm) this.deleteAllFiles();
        })
        .catch(() => {});
    },
    deleteAllFiles() {
      apiClient.delete('/facturas/all', {
        data: {
          ProvId: this.provId,
        },
      })
        .then(() => {
          this.destroyFile();
        })
        .catch(() => {
        });
    },
    iFrameLoaded() {
      URL.revokeObjectURL(this.srcFile);
    },
    validarCfdis() {
      const cfdis = this.selectedRows;
      if (cfdis === 0) return;
      this.loading = true;
      this.loadingVal = true;
      let count = 0;
      // eslint-disable-next-line array-callback-return
      Promise.all(cfdis.map((cfdi) => {
        this.setCellLoading(cfdi.data.FacturaId, 'Validando...');
        return apiClient.get('/facturas/validar', {
          params: {
            facturaId: cfdi.data.FacturaId,
            nomArchivo: cfdi.data.NomArchivo,
          },
        }).finally(() => {
          count += 1;
          this.progress = Math.round((count * 100) / cfdis.length);
        });
      })).then((res) => {
        let errores;
        res.forEach((response) => {
          if (response?.status === 202) {
            errores = (errores ? `${errores}<br>❌${response.data.ms}` : `❌${response.data.ms}`);
          }
        });
        // this.getFilesData();
        this.loading = false;
        this.loadingVal = false;
        this.successfulLoaded(errores);
      }).catch((err) => {
        this.loading = false;
        this.loadingVal = false;
        this.progress = 0;
        this.$bvModal.msgBoxOk(err.response.data.ms || '', {
          title: err.response.data.title || 'Atención',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'OK',
          cancelTitle: 'Cancelar',
          headerClass: 'text-light',
          bodyClass: 'max-height-70',
          footerClass: 'bg-light p-2',
          hideHeaderClose: true,
          centered: true,
        }).then(() => {
          this.successfulLoaded();
        })
          .catch(() => {
            this.successfulLoaded();
          });
        // this.successfulLoaded();
      });
    },
    successfulLoaded(modalMsg = '') {
      this.$emit('validated', modalMsg);
    },
    descargarSeleccionados() {
      this.loading = true;
      this.loadingDown = true;
      const endpoint = this.todas ? '/facturas/all/multiple' : '/facturas/multiple';
      apiClient.get(endpoint, {
        responseType: 'blob',
        params: {
          ProvId: this.provId,
          ids: this.selectedRowKeys,
        },
      })
        .then((res) => {
          const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1];
          const link = document.createElement('a');
          link.href = URL.createObjectURL(res.data);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(res.data);
          URL.revokeObjectURL(link.href);
          this.loading = false;
          this.loadingDown = false;
        })
        .catch(() => {
          this.loading = false;
          this.loadingDown = false;
        });
    },
    setCellLoading(rowId, newValue = '') {
      const rowNode = this.agGrid.api.getRowNode(rowId.toString());
      rowNode.setDataValue('EstatusSAT', newValue);
    },
    getEmpresas() {
      apiClient.get('/empresas', {
        params: {
          viewall: 1,
        },
      })
        .then((res) => {
          // res.data.unshift({ EmpresaId: 0, Nombre: '(TODAS)', Alias: '(TODAS)' });
          this.empresas = res.data;
        })
        .catch(() => {});
    },
    cambiarEmpresa() {
      this.getData();
    },
    generarContrarecibo() {
      const rowsWithValidTipo = this.selectedRows.filter((row) => row?.data?.Tipo !== 'P');
      if (rowsWithValidTipo.length > 0) {
        this.loadingRecibo = true;
        this.nomProveedor = this.nom_prov;
        apiClient.get('/recibos/partidas-select', {
          params: {
            ProvId: this.provId,
            ids: this.selectedRowKeys,
          },
        })
          .then((res) => {
            this.partidasRecibo = res.data;
            this.loadingRecibo = false;
            this.$nextTick(() => {
              this.generarPdf();
            });
          })
          .catch(() => {
            this.loadingRecibo = false;
          });
      } else {
        this.$bvModal.msgBoxOk('Los R.E.P. (Complementos de Pago) no requieren generar contra recibo.', {
          title: 'Atención',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'OK',
          cancelTitle: 'Cerrar',
          headerClass: 'text-light',
          bodyClass: 'max-height-70',
          footerClass: 'bg-light p-2',
          hideHeaderClose: true,
          centered: true,
        });
      }
    },
    generarPdf() {
      const element = this.$refs.recibo.$el;
      const clonedElement = element.cloneNode(true);
      clonedElement.style.display = 'block';

      const date = this.$formatDateToString(new Date());
      const opt = {
        margin: 0.25,
        filename: `Contrarecibo_${date}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        pagebreak: { mode: 'avoid-all', after: '#recibo' },
      };

      html2pdf().set(opt).from(clonedElement).save();
    },
  },
  watch: {
    refresh() {
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
