<template>
  <div>
    <b-badge class="font-size-12"
      v-b-popover.hover.html="`<strong>Ultima validación:</strong> ${params.data.UltimaValidacion}`"
      :variant="(params.data.EstatusSAT === 'Vigente' ? 'success' : 'secondary')">
      {{ params.data.EstatusSAT }}
    </b-badge>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: [
  ],
  data() {
    return {
    };
  },
  methods: {
  },
});
</script>

<style lang="scss" scoped>
</style>
