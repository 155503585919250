<template>
  <div class="card-events">
      <ul class="list-unstyled media-list mg-b-0">
          <li class="media">
            <div class="media-left">
              <p class="alert-icon" :class="variant">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: `${icon || 'exclamation-circle'}`, }"/>
              </p>
            </div>
            <div class="media-body" :class="variant">
              <span class="event-time">{{ title }}</span>
              <h6 class="event-title">{{ content }}</h6>
            </div>
          </li>
      </ul>
  </div>
</template>
<script>
export default {
  name: 'Alert',
  props: [
    'variant',
    'title',
    'content',
    'icon',
  ],
};
</script>

<style lang="scss" scoped>
.mg-b-0 {
    margin-bottom: 0px;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
}
.media {
    display: flex;
    align-items: flex-start;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
}
li.media div.media-left {
    align-self: center;
}
.card-events .media-left {
    min-width: 30px;
    text-align: center;
}
.card-events .media-left p {
    margin-bottom: 0;
    font-family: "Rubik", sans-serif;
    font-size: 24px;
    line-height: 1;
    letter-spacing: -1px;
}
.card-events .media-body {
  margin-left: 15px;
  padding: 13px 15px;
  border-left: 3px solid #E1E8EB;
  background-color: rgba(225, 232, 235,0.4);
  color: #343A40;
  &.success {
    background-color: rgba(16, 183, 89, 0.1);
    border-left-color: #10b759;
    color: #08592b;
  }
  &.danger {
    background-color: rgba(183, 16, 16, 0.1);
    border-left-color: #b71010;
    color: #590808;
  }
}
.alert-icon {
  svg {
    color: #f4f5f8;
  }
  &.success svg {
    color: #168736;
  }
  &.danger svg {
    color: #dc3545;
  }
}
.media-body {
    flex: 1;
}
.card-events .event-time {
    display: block;
    font-size: 11px;
    margin-bottom: 5px;
    opacity: .6;
}
.card-events .event-title {
    margin-bottom: 0;
    font-size: 13px;
}
</style>
