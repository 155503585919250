<template>
  <div class="text-ellipsis">
    <span class="mr-1"
    :class="value !== 'N/A' && params.data.Tipo === 'I' && params.data.MetodoPago === 'PPD' && params.data.Total === totalReps ? 'pointer' : ''"
    :title="value !== 'N/A' && params.data.Tipo === 'I' && params.data.MetodoPago === 'PPD' && params.data.Total === totalReps ? 'R.E.P. Relacionados' : '' ? 'R.E.P. Relacionados' : ''"
    v-b-popover.hover.focus.html="value !== 'N/A' && params.data.Tipo === 'I' && params.data.MetodoPago === 'PPD' && params.data.Total === totalReps ? popoverText : ''"
    >
      <font-awesome-icon :icon="{ prefix: 'fa', iconName: icon, }" :style="{ color: iconColor }" class="icon btn-icon"/>
    </span>
    <span
    v-if="params.data.Tipo === 'I' && params.data.MetodoPago === 'PPD' && params.data.EsPpdPagado === 1 && params.data.Total !== totalReps"
    :class="value !== 'N/A' ? 'pointer' : ''"
    :title="value !== 'N/A' && params.data.Tipo === 'I' ? 'R.E.P. Relacionados' : ''"
    v-b-popover.hover.focus.html="value !== 'N/A' && params.data.Tipo === 'I' ? popoverText : ''"
    >
      <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-triangle', }" :style="{ color: '#d88f18 ' }" class="icon btn-icon"/>
    </span>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: [],
  data() {
    return {
      popoverText: '⚠️Factura PPD Pagada sin REP relacionados',
      value: 'N/A',
      icon: 'file-invoice',
      iconColor: '#6d4381',
      totalReps: 0,
    };
  },
  mounted() {
    this.icon = this.params.data.Tipo === 'P' ? 'file-invoice-dollar' : this.icon;
    this.icon = this.params.data.Tipo === 'E' ? 'receipt' : this.icon;

    this.iconColor = this.params.data.Tipo === 'P' ? '#0078d6' : this.iconColor;
    this.iconColor = this.params.data.Tipo === 'E' ? '#6dc7ad' : this.iconColor;

    this.value = (this.params.data.Tipo !== 'I' ? 'N/A' : this.$currencyFormat(this.params.data[this.params.column.colId]));
    let fechasReps = this.params.data.FechasReps;
    let foliosReps = this.params.data.FoliosReps;
    let monedaReps = this.params.data.MonedaReps;
    let impPagadoReps = this.params.data.ImpPagadoReps;

    if (fechasReps && monedaReps && impPagadoReps) {
      fechasReps = fechasReps.split(',');
      foliosReps = foliosReps.split(',');
      monedaReps = monedaReps.split(',');
      impPagadoReps = impPagadoReps.split(',');
      if (fechasReps.length > 0 && foliosReps.length > 0 && monedaReps.length > 0 && impPagadoReps.length > 0) {
        this.popoverText = '';
        fechasReps.forEach((row, index) => {
          this.popoverText = `${this.popoverText}<strong>Fecha: </strong>${fechasReps[index]}<br><strong>Folio: </strong>${foliosReps[index]}<br><strong>Moneda: </strong>${monedaReps[index]}<br><strong>Pago: </strong>${this.$currencyFormat(impPagadoReps[index])}<hr class="thin">`;
          this.totalReps += Number(impPagadoReps[index]);
        });

        this.popoverText = `${this.popoverText}<strong>TOTAL CFDi:</strong> ${this.$currencyFormat(this.params.data.Total)}<br><strong>TOTAL REPS:</strong> ${this.$currencyFormat(this.totalReps)}`;
        if (this.params.data.Total !== this.totalReps) {
          this.popoverText = `⚠️Factura PPD con REPs pendientes<br><br>${this.popoverText}`;
        }
      }
    }
  },
  methods: {
  },
});
</script>
<style land="scss" scoped>
.b-popover {
  overflow-y: scroll;
  max-height: 300px;
}
.text-left {
  text-align: left;
}
</style>
