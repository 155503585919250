<template>
  <div class="row mt-3">

    <div class="col-md-6 col-xs-12 mb-3">
      <b-card class="height-200 overflow card-valid" header="Validaciones">
        <span v-for="(validacion, index) in validaciones" :key="index">
          <span v-if="validacion.Tipo === 1">
            <alert :variant="'success'" :title="validacion.NomArchivo" :content="validacion.Descripcion" :icon="'check-circle'"></alert><br>
          </span>
        </span>

        <span v-if="!validaciones.find((v) => v.Tipo === 1)">
          <alert :variant="'default'" title="Sin Validaciones" :content="''" :icon="'check-circle'"></alert><br>
        </span>
      </b-card>
    </div>

    <div class="col-md-6 col-xs-12 mb-3">
      <b-card class="height-200 overflow card-risk" header="Riesgos">
        <span v-for="(validacion, index) in validaciones" :key="index">
          <span v-if="validacion.Tipo === 0">
            <alert :variant="'danger'" :title="validacion.NomArchivo" :content="validacion.Descripcion" :icon="'times-circle'"></alert><br>
          </span>
        </span>

        <span v-if="!validaciones.find((v) => v.Tipo === 0)">
          <alert :variant="'default'" title="Sin Riesgos" :content="''" :icon="'check-circle'"></alert><br>
        </span>

      </b-card>
    </div>

  </div>
</template>

<script>
import Alert from '@/components/Alert.vue';

export default {
  name: 'ValidacionesBox',
  components: {
    Alert,
  },
  props: [
    'validaciones',
  ],
};
</script>

<style lang="scss" scoped>

</style>
