<template>
  <div class="text-ellipsis">
    <span class="pointer"
      v-b-popover.hover.html="params.popoverText">
      {{ params.data[params.column.colId] }}
    </span>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: [
    'popoverText',
  ],
  data() {
    return {
    };
  },
  methods: {
  },
});
</script>
