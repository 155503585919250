<template>
  <div class="text-ellipsis"
    :class="value !== 'N/A' && params.data.Tipo === 'I' && params.data.MetodoPago === 'PPD' ? 'pointer' : ''"
    :title="value !== 'N/A' && params.data.Tipo === 'I' && params.data.MetodoPago === 'PPD' ? 'R.E.P. Relacionados' : ''"
    v-b-popover.hover.focus.html="value !== 'N/A' && params.data.Tipo === 'I' && params.data.MetodoPago === 'PPD' ? popoverText : ''"
  >
    <span>
      {{ value }}
    </span>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: [],
  data() {
    return {
      popoverText: '⚠️Factura PPD sin REPs relacionados',
      value: 'N/A',
      totalReps: 0,
    };
  },
  mounted() {
    this.value = (this.params.data.Tipo !== 'I' ? 'N/A' : this.$currencyFormat(this.params.data[this.params.column.colId]));
    let fechasReps = this.params.data.FechasReps;
    let monedaReps = this.params.data.MonedaReps;
    let impPagadoReps = this.params.data.ImpPagadoReps;

    if (fechasReps && monedaReps && impPagadoReps) {
      fechasReps = fechasReps.split(',');
      monedaReps = monedaReps.split(',');
      impPagadoReps = impPagadoReps.split(',');
      if (fechasReps.length > 0 && monedaReps.length > 0 && impPagadoReps.length > 0) {
        this.popoverText = '';
        fechasReps.forEach((row, index) => {
          this.popoverText = `${this.popoverText}<strong>Fecha: </strong>${fechasReps[index]}<br><strong>Moneda: </strong>${monedaReps[index]}<br><strong>Pago: </strong>${this.$currencyFormat(impPagadoReps[index])}<hr>`;
          this.totalReps += Number(impPagadoReps[index]);
        });

        this.popoverText = `${this.popoverText}<strong>TOTAL CFDi:</strong> ${this.$currencyFormat(this.params.data.Total)}<br><strong>TOTAL REPS:</strong> ${this.$currencyFormat(this.totalReps)}`;
        if (this.params.data.Total !== this.totalReps) {
          this.popoverText = `⚠️Factura PPD con REPs pendientes<br><br>${this.popoverText}`;
        }
      }
    }
  },
  methods: {
  },
});
</script>
<style land="scss" scoped>
.b-popover {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px;
}
</style>
