<template>
    <div class="btn-action-md">
      <b-button class="btn-detail btn-icon-text1 mr-1"
        v-if="$descargar(['Facturas'])"
        @click="$descargar(['Facturas']) && params.data.FacturaId ? viewFile('P') : undefined"
        :disabled="params.loading"
        v-b-popover.hover.left="'Ver PDF y Metadata'">
        <font-awesome-icon v-if="params.loadingViewId == params.data.FacturaId" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
        <font-awesome-icon v-else :icon="{ prefix: 'fa', iconName: 'file-pdf' }" class="icon btn-icon"/>
        PDF</b-button>
      <b-button class="btn-detail btn-icon-text1 mr-1"
        v-if="$descargar(['Facturas'])"
        @click="$descargar(['Facturas']) && params.data.FacturaId ? viewFile('X') : undefined"
        :disabled="params.loading"
        v-b-popover.hover="'Ver XML y Metadata'">
        <font-awesome-icon v-if="params.loadingViewId == params.data.FacturaId" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
        <font-awesome-icon v-else :icon="{ prefix: 'fa', iconName: 'file-code' }" class="icon btn-icon"/>
        XML</b-button>
      <b-button class="btn-detail mr-1"
        v-if="$descargar(['Facturas'])"
        @click="$descargar(['Facturas']) && params.data.FacturaId ? downloadFiles() : undefined"
        :disabled="params.loading"
        v-b-popover.hover="'Descargar XML y PDF'">
        <font-awesome-icon v-if="params.loadingDownId == params.data.FacturaId" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
        <font-awesome-icon v-else :icon="{ prefix: 'fa', iconName: 'download', }" class="icon btn-icon"/>
        </b-button>
      <b-button class="btn-detail" v-if="$eliminar(['Facturas']) && !params.es_proveedor && !params.todas"
        @click="params.data.FacturaId ? showDeleteFile() : undefined"
        :disabled="params.loading"
        v-b-popover.hover="'Eliminar archivo'">
        <font-awesome-icon v-if="params.loadingDelId == params.data.FacturaId" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
        <font-awesome-icon v-else :icon="{ prefix: 'fa', iconName: 'trash-alt', }" class="icon btn-icon"/>
      </b-button>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: [
    'loading',
    'loadingViewId',
    'loadingDownId',
    'loadingDelId',
    'es_proveedor',
    'todas',
  ],
  data() {
    return {
    };
  },
  methods: {
    viewFile(formato) {
      this.params.context.componentParent.viewFile(
        this.params.data.FacturaId, this.params.data.Metadata, formato,
      );
    },
    downloadFiles() {
      this.params.context.componentParent.downloadFiles(
        this.params.data.FacturaId, this.params.data.NomArchivo,
      );
    },
    showDeleteFile() {
      this.params.context.componentParent.showDeleteFile(this.params.data.FacturaId);
    },
  },
});
</script>

<style lang="scss" scoped>
.btn-icon {
  padding: 0 .30rem;
  width: 1.2rem !important;
  height: auto;
}
.btn-detail {
  &.btn-icon-text1 {
    width: 55px;
  }
}
</style>
